.main-social {

    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 60px;
}


.social {
    background: #000000;
    border-radius: 20px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px;
}


.about-title {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 20px;
}

.social p {
    font-size: 20px;
}

.section-icon {
    display: flex;
    gap: 28px;
    margin-top: 40px;
    justify-content: center
}

.section-icon a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-icon a .MuiSvgIcon-root {
    width: 42px !important;
    height: 42px !important;
}

#twit {
    color: #1DA1F2;
}

#face {
    color: #1877F2;
}

#ins {
    color: #ec2929;
}

#link-in {
    color: #0A66C2;
}

#pin {
    color: #E60019;
}

#youtube {
    color: #FF0302;
}

#reddit {
    background: #FF4500;
    border-radius: 20px;
}

#telegram {
    color: #1DA1F2;
}




/***********
         media query 
           *****************/


@media (max-width: 1024px) {
    .main-social {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

@media (max-width: 414px) {
    .section-icon {
        gap: 6px;
    }

    .section-icon a .MuiSvgIcon-root {
        width: 28px !important;
        height: 28px !important;
    }

    .social p {
        font-size: 14px;
    }

    .about-title {
        margin-bottom: 20px;
    }

    .about-title {
        font-size: 24px;
    }
}

@media (max-width: 360px) {
    .section-icon {
        gap: 10px !important;
    }

    .section-icon a .MuiSvgIcon-root {
        width: 23px !important;
        height: 23px !important;
    }
}