
body {
  font-family: 'Inter', sans-serif;
}

.background {
  background: linear-gradient(180deg, rgba(238, 50, 38, 0.2) 0%, rgba(255, 127, 44, 0) 100%);
  height: 930px;
  height: 945px;
  top: 0px;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/***********
         media query 
           *****************/
           
@media (max-width: 1920px) {
  .background {
    background: linear-gradient(180deg, rgba(238, 50, 38, 0.2) 0%, rgba(255, 127, 44, 0) 100%);
  }
}

@media (max-width: 414px) {
  .background {
    background: linear-gradient(180deg, rgba(238, 50, 38, 0.2) 0%, rgba(255, 127, 44, 0) 100%);
    height: 540px;
    width: 100%;
    top: 0px;
  }
}