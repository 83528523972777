.top-service {
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    text-align: center;
    margin-top: 4px;
    color: #EE3626;

}

.time {
    color: #101828;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 40px;
}



/***********
         media query 
           *****************/

@media (max-width: 1020px) {

    .card {
        max-width: 384px;
    }
}
@media (max-width: 360px) {
    .card {
        max-width: 320px;
    }
}
@media (max-width: 414px) {
    .all-option{
        margin: 46px 0;
    }
}