.wave-container {
  margin-bottom: -308px;
}
.wave-banner {
  position: relative;
  overflow: hidden;
  text-align: center;
  height: 910px; /* Adjust the height as needed */
}


.triangle-0 {
  margin-left: 22%;
  margin-top: 5%;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  /* Adjust the size of the triangle */
  border-right: 0px solid transparent;
  border-bottom: 24px solid #F7CC7F;
  /* Adjust the color of the triangle */
  transform: rotate(72deg);
}
.triangle-2 {
  margin-left: 80%;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 24px solid #F48C7F;
  transform: rotate(-38deg);
}

.triangle-3 {
  margin-left: 7%;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 24px solid #50BFA5;
  transform: rotate(251deg);
}

.triangle-4 {
  margin-left: 7%;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 24px solid #50BFA5;
  transform: rotate(202deg);
}

.triangle-5 {
  margin-left: 85%;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 24px solid #F48C7F;
  transform: rotate(45deg);
}

.start-button {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: white;
  background: #EE3626;
  width: 157px;
  height: 60px;
}

.customer {
  display: flex;
  justify-content: space-around;
  margin-bottom: -10px;
  margin: auto;
}

.mini {
  height: 18px !important;
  width: 18px !important;
  background: #CDCEFF !important;
}

.mini-1 {
  background: #FFB9DB !important;
}

.round {
  width: 60px;
  height: 60px;
  background: #FFB15F;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 36px;
}

#happy {
  background: #FAD4BD;
}

#Tickets {
  background: #FF9FCE;
}

#team {
  background: #FE9696;
}

.title {
  width: 450px;
  margin: auto;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 72px;
  margin-top: -50px;
}




/***********
         media query 
           *****************/


@media (max-width: 768px) {
  .wave-curve {
    transform: scaleX(1.5) scaleY(0.8);
    width: 100% !important;
  }
}

@media (max-width: 414px) {
  .title {
    width: 70%;
    margin: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 46px;
}
.mini{
  display: none;
}
.wave-container {
  margin-bottom: -612px;
}
.start-button {
  margin-top: 23px;
}
}
@media (max-width: 1024px) {
  .customer {
    max-width: 973px;
  }
}