.side-menu{
    background: #FCDEDA;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus), .menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):hover){
    background-color: #f1f5f9;
}
.custom-menu-padding{
    padding: 15px 0px 15px 15px;
}


/***********
         media query 
           *****************/
           
@media (max-width: 414px) {
    
    .menu{
        width: fit-content;
    height: fit-content;
    }

  }