.navbar{
    padding: 30px 120px;
}
.menu-horizontal {
    column-gap: 20px !important;
}

.navbar-center {
    background: #FFFFFF;
    border-radius: 40px;
}
.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus), .menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):hover) {
    background-color: #000000;
    color: white !important;
    background: #000000 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 30px;
}

.navbar-end .btn {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px 16px !important;
    gap: 8px !important;
    /* width: 95px !important;
    height: 40px !important; */
    background: #EE3626 !important;
    border: 1px solid #EE3626 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FDEBE9 !important;
    border-radius: 40px !important;
    font-size: 14px !important;
}
.navbar-end {
    width: 12% !important;
}
.navbar-start{
    width: fit-content;
}





/***********
         media query 
           *****************/

           
@media (max-width: 414px) {
    .navbar {
        padding: 47px 7px;
    }
    .menu{
        background: white;
    }

  }
@media (max-width: 1024px) {
    .navbar {
        padding: 30px 10px;
        margin: 0;
    }
    .navbar-end {
        width: 20% !important;
    }
    .navbar-start{
        width: fit-content;
    }
    .navbar-end {
        width: 12% !important;
    }
    
  }