/* top banner */

.wave-banner {
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 910px;
}

.hero-content {
    padding: 0 !important;
}

.wave-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.banner-title {
    display: flex;
    margin-top: 190px;
    justify-content: center;
    height: 100%;
    padding: 0;
}

.rdp-nav_button_previous {
    display: none;
}
.rdp-table {
    table-layout: auto;
}

.rdp {
    --rdp-cell-size: 70.58px !important;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    margin: 1em;
    left: calc(50% - 526px/2 - 0.5px);
}

.rdp-caption {
    font-weight: 600 !important;
    font-size: 33.7179px !important;
    line-height: 46px !important;
    color: black !important;
    font-style: normal !important;
}

.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: 'Inter', sans-serif !important;
    font-size: 33.7179px !important;
}
.rdp-button_reset {
    font-family: 'Inter', sans-serif !important;
}

.calender {
    border-radius: 30.3462px;
    box-shadow: 5.05769px 6.74359px 16.859px 3.37179px rgba(0, 0, 0, 0.05);
    width: 526px;
    height: auto;
    z-index: 2222;
}

.rdp-caption_label {
    font-family: 'Inter', sans-serif !important;
    font-size: 33.7179px!important;
}




/***********
         media query 
           *****************/


@media (max-width: 414px) {
    .hero {
        justify-content: center;
    }
    .wave-banner{
        height: 620px;
    }
    .calender {
        width: 357px;
    }
    .rdp{
        --rdp-cell-size: 48.58px !important;
    }
  }

  @media (max-width: 360px) {
    .wave-banner{
        height: 620px;
    }
    .calender {
        width: 320px;
    }
    .rdp{
        --rdp-cell-size: 45.58px !important;
    }
}