.main-about{
    background: black;
    height: 811px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
.about{
    display: flex;
    align-items: center;
    justify-content: center;
    font: white;
}
.image img{
    width: 588px;

}

.image{
    width: 100%;
}





/***********
         media query 
           *****************/

@media (max-width: 1024px) {
    .main-about{
        height: auto !important;
        padding: 80px 0px;
    }
  }
@media (max-width: 678px) {
    .sub-about{
        padding: 0 30px;
    }
  }
@media (max-width: 414px) {
    .main-about {
        margin-bottom: 50px;
    }
  }