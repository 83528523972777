.main-client-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF3F1;
}

#client-title {
    color: #EE3626;
    font-weight: 800;
    font-size: 20px;
}

.mid-client {
    padding: 96px;
    width: 100%;
    height: auto;
}

.mid-client .slide-img {
    margin: auto;
}

.slick-slide img {
    display: block;
    margin: auto !important;
}

.slide-img {
    width: 64px;
    height: 64px;
}

.slide-content {
    height: 100%;
    text-align: center;
    gap: 32px;
}

.cont {
    font-size: 36px;
    font-weight: 600;
    color: #101828;
    margin: 32px;
}

#des {
    color: #101828;
}

#company {
    color: #667085;
}



/***********
         media query 
           *****************/

@media (max-width: 414px) {
    .cont {
        font-size: 14px;
        margin: 13px 0;
    }
}